export const groups = {
	AdReportStatus: 'adreportstatus',
};
export const methods = {
	Get: 'get',
};

export const reportingProductType = {
	all: { key: 0, value: 'All' },
	gameDistribution: { key: 1, value: 'GameDistribution' },
	video: { key: 4, value: 'Video' },
	display: { key: 7, value: 'Display' },
	mobile: { key: 8, value: 'Mobile' },
	inGame: { key: 12, value: 'Ingame' },
	voidu: { key: 14, value: 'Voidu' },
	premiumCampaign: { key: 15, value: 'PremiumCampaign' },
	alarmData: { key: 31, value: 'AlarmData' },
	summary: { key: 33, value: 'Summary' },
	compare: { key: 34, value: 'Compare' },
};

export const transferType = {
	bankSepa: 1,
	paypal: 2,
	bankNonSepa: 3,
};

export const applicationMessageDisplayType = {
	NOTIFICATION: 1,
	POPUP: 2,
	CONFIRMATION: 3,
	DASHBOARD: 4,
};

export const applicationMessageType = {
	NONE: 0,
	INFO: 1,
	WARNING: 2,
	SECURITY: 3,
	TERMS_AND_CONDITIONS: 4,
};

export const applicationMessageTargetType = {
	ALL: 1,
	INTERNAL: 2,
	EXTERNAL_ALL: 3,
	EXTERNAL_SPECIFIC: 4,
};

export default {
	groups,
	methods,
	reportingProductType,
	transferType,
	applicationMessageDisplayType,
	applicationMessageType,
	applicationMessageTargetType,
};
