/* eslint-disable no-unused-expressions */
import { applicationMessageTargetType } from '@/constants/global';
import { minLength, required } from 'vuelidate/lib/validators';

const validations = {
	validations: {
		form: {
			editingItem: {
				messageType: {
					required,
				},
				displayType: {
					required,
				},
				title: {
					required,
				},
				text: {
					required,
				},

				partners: {
					minLength: (value, model) => {
						if (model.targetType !== applicationMessageTargetType.EXTERNAL_SPECIFIC) {
							return true;
						}
						return minLength(1)(value);
					},
				},
			},
		},
	},
	methods: {
		// Validation methods for this page
		partnerIndexErrors() {
			const errors = [];
			const { $dirty, minLength } = this.$v.form.editingItem.partners;
			if (!$dirty) return errors;
			!minLength && errors.push(`Please select at least 1 item`);
			return errors;
		},
	},
};

export default validations;
