<template>
	<div>
		<UserVerification />
		<ConfirmationBox />
		<v-dialog
			v-if="insertPermission || updatePermission || form.preview"
			v-model="form.editDialog"
			max-width="1300px"
			:width="width"
			persistent
			scrollable
			:retain-focus="false"
			@keydown.esc="escapeEvent"
		>
			<template #activator="{ on: dialog, attrs }">
				<v-snackbar v-model="discardChanges" top :timeout="-1">
					Are you sure you want to discard the changes
					<template #action="{ attrs }">
						<v-btn color="green" text v-bind="attrs" @click="closeDataFormDialog"> Yes </v-btn>
						<v-btn color="red" text v-bind="attrs" @click="discardChanges = false"> No </v-btn>
					</template>
				</v-snackbar>
				<v-btn
					v-if="insertPermission"
					class="my-1"
					:class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }"
					:block="$vuetify.breakpoint.smAndDown"
					:small="openButton.small"
					:color="openButton.color"
					:dark="openButton.dark"
					v-bind="attrs"
					v-on="{ ...dialog }"
				>
					<v-icon left :size="computedButtonIconSize">{{ openButton.icon }}</v-icon>
					{{ $t(openButton.text) }}
				</v-btn>
			</template>
			<v-card>
				<v-card-title class="primary white--text text--lighten-1">
					{{ title }}<v-spacer></v-spacer>
					<v-btn icon dark @click="closeDataFormDialog">
						<v-icon>fa-times-circle</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<transition name="fade" mode="out-in">
						<component :is="form.name" :form="form"></component>
					</transition>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="px-5 py-3">
					<v-spacer></v-spacer>
					<!-- dynamic custom buttons -->
					<template v-if="customButtonVisible">
						<CustomButton
							v-for="(button, index) in form.customButtons"
							:key="'formButton#' + index"
							:show="button.show"
							:disable="button.disable"
							:button="button"
						/>
					</template>
					<!-- dynamic custom buttons end -->
					<v-btn
						v-if="saveButtonVisible"
						small
						color="success"
						:disabled="form.invalid"
						:loading="loading"
						@click="saveItem"
					>
						<v-icon left x-small>fa-save</v-icon>
						{{ $t('save') }}
					</v-btn>
					<v-btn small color="pink darken-2" dark @click="closeDataFormDialog">
						<v-icon left x-small>fa-times</v-icon> {{ $t('cancel') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import dataFormDialogComponentMixin from '@/mixins/dataFormDialogComponentMixin';
import { ApiService, checkPermission, confirmationMixin, userVerificationMixin } from '@headerlift/library';

export default {
	name: 'DataFormDialog',
	mixins: [dataFormDialogComponentMixin, userVerificationMixin, confirmationMixin],
	props: {
		serviceName: {
			type: String,
		},
		insertMethodName: {
			type: String,
			default: 'insert',
		},
		updateMethodName: {
			type: String,
			default: 'update',
		},
		openButton: {
			type: Object,
			default: () => ({
				block: false,
				color: 'primary',
				icon: 'fa-plus',
				dark: true,
				small: true,
				text: 'addNewItem',
			}),
			required: false,
		},
		form: {
			type: Object,
			default: () => {},
			required: true,
		},
		getPresetEndPoint: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			loading: false,
			title: '',
			customButtonVisible: false,
			formIsLoaded: false,
			discardChanges: false,
			formDataIsChanged: false,
			editingItemWatchSize: 0,
		};
	},
	computed: {
		computedButtonIconSize() {
			return this.openButton.small ? 10 : 12;
		},
		saveButtonVisible() {
			return this.form.save;
		},
		insertPermission() {
			if (this.insertMethodName === null) return false;
			return checkPermission(this.$store, {
				serviceName: this.serviceName,
				methodName: this.insertMethodName,
			});
		},
		updatePermission() {
			if (this.updateMethodName === null) return false;
			return checkPermission(this.$store, {
				serviceName: this.serviceName,
				methodName: this.updateMethodName,
			});
		},
		width() {
			return this.form.width ?? null;
		},
	},
	watch: {
		'form.editDialog': {
			handler(dialogIsOpen) {
				this.loading = false;
				if (dialogIsOpen && this.getPresetEndPoint) {
					this.getPresetData()
						.then((data) => {
							this.form.presetData = data;
							this.customButtonVisible = true;
						})
						.finally(() => {
							if (this.form.preview) this.title = this.form.title ? this.form.title : this.$i18n.t('preview');
							else if (this.form.title) this.title = this.form.title;
							else this.title = this.form.editedIndex === -1 ? this.$i18n.t('addNewItem') : this.$i18n.t('editItem');
						});
				} else {
					this.form.preview = false;
					this.title = '';
					this.editingItemWatchSize = 0;
					this.formDataIsChanged = false;
					this.formIsLoaded = false;
				}
			},
		},
		'form.editingItem': {
			handler(val, oldVal) {
				if (!this.form.editDialog) return;

				this.editingItemWatchSize += 1;
				if (this.formIsLoaded && this.editingItemWatchSize > 2) this.formDataIsChanged = true;
				if (oldVal[this.form.pkField] && !this.formIsLoaded) this.formIsLoaded = true;
			},
			deep: true,
		},
	},
	methods: {
		escapeEvent() {
			if (this.formDataIsChanged) {
				this.discardChanges = true;
				return;
			}
			this.form.editDialog = false;
			this.loading = false;
			this.$emit('closeDataFormDialog');
		},
		getPresetData() {
			const id = this.form.editingItem[this.form.pkField];
			return new Promise((resolve, reject) => {
				ApiService.post(this.getPresetEndPoint, { id })
					.then((data) => {
						if (!data) return;
						resolve(data);
					})
					.catch((response) => {
						if (response.responseCode !== 200) {
							setTimeout(() => {
								this.closeDataFormDialog();
							}, 1000);
						}
						reject(response);
					});
			});
		},
		saveItem() {
			this.verifyUser(this.save);
		},
		save() {
			this.loading = true;
			return this.$emit('saveItem', this.onSuccess, this.onCatch);
		},
		// eslint-disable-next-line no-unused-vars
		onSuccess(response) {
			this.loading = false;
		},
		onCatch(response) {
			if (response.responseCode === 700) {
				this.showConfirmationBox(this.save, response.responseText);
			}
			this.loading = false;
		},
		closeDataFormDialog() {
			if (this.discardChanges) {
				this.loading = false;
				this.editingItemWatchSize = 0;
				this.discardChanges = false;
			}
			this.$emit('closeDataFormDialog');
		},
	},
};
</script>

<style></style>
